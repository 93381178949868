import React from "react"
import styled from 'styled-components'
import CustomMedia from "../../helpers/media";
import close from '../../assets/images/close.svg'

const MainWrap = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.61);
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  ${CustomMedia.lessThan('mobile')`
    padding: 90px 0 0;
    align-items: start;
  `}
`

const SubWrapper = styled.div`
  background-color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 20px;
  position: relative;
  ${CustomMedia.lessThan('mobile')`
    margin: 0 10px;
  `}
`

const Wrapper = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
  margin: 0 auto;
  width: 100%;
  max-width: 728px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
    ${CustomMedia.lessThan('mobile')`
        width: 3px;
  `}
  }

  &::-webkit-scrollbar-thumb {
    background: #5A14EF;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.15);;
  }
  ${CustomMedia.lessThan('mobile')`
    height: 50vh;
    padding: 15px;
  `}
`

const PolicyTitle = styled.p`
  font-weight: 834;
  font-size: 40px;
  line-height: 100%;
  color: #0B0B0B;
  font-stretch: 132%;
  margin-bottom: 25px;
  font-variation-settings: 'GRAD' -188, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 98, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 42;
  ${CustomMedia.lessThan('mobile')`
    font-size: 25px;
line-height: 25px;
  `}
`

const CustomHref = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(40, 40, 40, 0.15);
  margin-bottom: 25px;
`

const PolicyPoint = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #0B0B0B;
  margin-bottom: 20px;
`

const CloseButton = styled.div`
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 14px;
  right: 25px;
  border-radius: 100px;
  cursor: pointer;
  ${CustomMedia.lessThan('mobile')`
    right: 24px;
    top: 14px;
    width: 50px;
    height: 50px;
  `}
`


const Policy = ({callback, show}) => {
  return (
    <MainWrap show={show}>
      <SubWrapper>
        <CloseButton onClick={callback}>
          <img src={close} />
        </CloseButton>
        <CustomHref />
        <Wrapper>
          <PolicyTitle>Политика<br /> конфиденциальности</PolicyTitle>
          <PolicyPoint>
            1. Обработке подлежат только персональные данные, которые отвечают целям их обработки.
          </PolicyPoint>
          <PolicyPoint>
            2. Содержание и объем обрабатываемых персональных данных должны соответствовать заявленным
            целям
            обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к
            заявленным целям их обработки.
          </PolicyPoint>
          <PolicyPoint>
            3. В соответствии с Политикой Компании обработка персональных данных необходима в целях:
          </PolicyPoint>
          <PolicyPoint>
            3.1. Защиты жизни, здоровья или иных жизненно важных интересов персональных данных
            Контрагентов
            Партнеров, если получение их согласия данных невозможно.
          </PolicyPoint>
          <PolicyPoint>
            3.2. Исполнения договора, стороной которого либо выгодоприобретателем или поручителем по
            которому является Компания или Контрагент, в том числе в случае реализации Компанией своего
            права на уступку прав (требований) по такому договору, а также для заключения договора по
            инициативе Компании или Контрагента, или
          </PolicyPoint>
          <PolicyPoint>3.3. Опубликования или обязательного раскрытия персональных данных Контрагентов
            Партнеров в соответствии с законодательством.
          </PolicyPoint>
          <PolicyPoint>3.4. Продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов
            с потенциальными Контрагентами с помощью средств связи.
          </PolicyPoint>
          <PolicyPoint>4. Обработка персональных данных должна осуществляться на законной и справедливой
            основе.
          </PolicyPoint>
          <PolicyPoint>5. Обработка персональных данных должна ограничиваться достижением конкретных,
            заранее определенных и законных целей. Не допускается обработка персональных данных,
            несовместимая с целями сбора персональных данных.
          </PolicyPoint>
          <PolicyPoint>6. При обработке персональных данных должны быть обеспечены точность персональных
            данных, их достаточность, а в необходимых случаях и актуальность по отношению к целям
            обработки персональных данных. Оператор персональных данных должен принимать необходимые
            меры либо обеспечивать их принятие по удалению или уточнению неполных или неточных данных
          </PolicyPoint>
          <PolicyPoint>7. Хранение персональных данных осуществляется Компанией, а также лицами, указанными
            в частях 5 и 6 статьи V Политики Компании, в документарной и (или) электронной формах, в
            течение срока, который составляет не менее 5 лет. Более продолжительный срок хранения
            персональных данных может быть установлен в договорах, стороной которых,
            выгодоприобретателем или поручителем по которым является Контрагент.
          </PolicyPoint>
          <PolicyPoint>8. Обработка персональных данных Контрагентов Партнеров осуществляется с их согласия
            на обработку таких персональных данных, за исключением случаев, предусмотренных
            законодательством и настоящей Политикой Компании.
          </PolicyPoint>
          <PolicyPoint>9. Контрагенты Компании выражают согласие на обработку их персональных данных
            Оператором персональных данных, за исключением иных Контрагентов и их физических лиц, а
            также третьих лиц в результате их неправомерного или случайного доступа к персональным
            данным.
          </PolicyPoint>
          <PolicyPoint>10. Оператор персональных данных, осуществляющий обработку персональных данных по
            поручению Компании, не обязан получать согласие Контрагентов на обработку их персональных
            данных.
          </PolicyPoint>
          <PolicyPoint>11. В случае, если Компания поручает обработку персональных данных другому Оператору
            персональных данных, ответственность перед Контрагентами за действия указанного лица несет
            Компания. Оператор персональных данных, осуществляющий обработку персональных данных по
            поручению Компании, несет ответственность перед Компанией.
          </PolicyPoint>
          <PolicyPoint>12. Оператор персональных данных, ответственный за организацию обработки
            персональных данных, в частности, обязан:
          </PolicyPoint>
          <PolicyPoint>12.1. Осуществлять внутренний контроль за соблюдением им и его работниками
            законодательства о персональных данных, в том числе требований к защите персональных данных.
          </PolicyPoint>
          <PolicyPoint>12.2. Доводить до сведения работников Оператора персональных данных положения
            законодательства о персональных данных, международных правовых актов, настоящей Политики
            Компании по вопросам обработки персональных данных, требований к защите персональных данных;
          </PolicyPoint>
          <PolicyPoint>12.3. Организовывать прием и обработку обращений и запросов Контрагентов или их
            представителей и (или) осуществлять контроль за приемом и обработкой таких обращений и
            запросов;
          </PolicyPoint>
          <PolicyPoint>12.4. Не передавать иным лицам исполнение принятых на себя обязательств в какой бы
            то ни было форме.
          </PolicyPoint>
          <PolicyPoint>13. Контрагент и его физические лица, а также третьи лица, которые в результате
            неправомерного или случайного доступа к персональным данным, могут осуществить их обработку,
            несут юридическую ответственность перед Компанией и (или) Оператором персональных данных.
          </PolicyPoint>
        </Wrapper>
      </SubWrapper>
    </MainWrap>

  )
}

export default Policy
