import React, {useState} from "react";
import styled from "styled-components";
import Footer from "../blocks/Footer";
import SubFooter from "../blocks/SubFooter";
import Policy from "../blocks/policy";
import FirstScreenFive from "../blocks/firstBlockFive";
import FormWrapFive from "../blocks/FormWrapFive";

const Wrapper = styled.div`
  width: 100%;
  min-width: 100%;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100%;
  background: #fff;
  position: relative;
  overflow: hidden;
`

function Five() {
  const [show, setShow] = useState(false)
  const [showForm, setShowForm] = useState(false)

  return (
    <Wrapper>
      <FirstScreenFive setShowForm={setShowForm}/>
      <FormWrapFive show={showForm} setShow={setShowForm} />
      <SubFooter cc />
      <Footer cc callback={() => setShow(true)} />
      <Policy callback={() => setShow(false)} show={show} />
    </Wrapper>
  );
}

export default Five;
