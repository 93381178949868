import React from "react";
import './assets/style/reset.css'
import {Route, Routes} from 'react-router-dom'
import Main from "./pages/main";
import Five from "./pages/five";

function App() {
  return (
    <Routes>
      <Route exact path='/five' element={<Five />} />
      <Route exact path='/' element={<Main />} />
    </Routes>
  );
}

export default App;
