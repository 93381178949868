import React from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask';
import CustomMedia from "../../helpers/media";

const InputElement = styled.p`
  color: ${props => props.inputerror ? '#282828' : '#282828'};
  background-color: ${props => props.inputerror ? '#F1F2F6' : '#fff'};
  border: 2px solid ${props => props.inputerror ? 'rgba(221, 19, 19, 0.5)' : 'rgba(40, 40, 40, 0.5)'};
  padding: 18px 15px;
  min-width: 100%;
  width: ${props => props.width ? props.width : '570px'};
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  border-radius: 6px;

  ${CustomMedia.lessThan('mobile')`
    width: ${props => props.width};
    padding: 11px 15px;
  `}
  ::-webkit-input-placeholder {
    color: rgba(40, 40, 40, 0.7);
    font-weight: 400;
  }

  ::-moz-placeholder {
    color: rgba(40, 40, 40, 0.7);
    opacity: 1;
    font-weight: 400;
  }

  :-ms-input-placeholder {
    color: rgba(40, 40, 40, 0.7);
    opacity: 1;
    font-weight: 400;
  }

  &:focus {
    border: 2px solid ${props => props.inputerror ? 'rgba(221, 19, 19, 0.5)' : 'rgba(40, 40, 40, 0.5)'};
    background-color: #fff;
  }
`
const MaskInputElement = styled(InputMask)`
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: ${props => props.inputerror ? '#282828' : '#282828'};
  font-weight: 400;
  background-color: ${props => props.inputerror ? '#F1F2F6' : '#fff'};
  position: relative;
  border: 2px solid ${props => props.inputerror ? 'rgba(221, 19, 19, 0.5)' : 'rgba(40, 40, 40, 0.5)'};
  padding: 18px 15px;
  width: ${props => props.width ? props.width : '570px'};
  border-radius: 6px;

  ${CustomMedia.lessThan('mobile')`
    width: ${props => props.width};
    padding: 11px 15px;
  `}
  ::-webkit-input-placeholder {
    color: rgba(40, 40, 40, 0.7);
    font-weight: 400;
  }

  ::-moz-placeholder {
    color: rgba(40, 40, 40, 0.7);
    opacity: 1;
    font-weight: 400;
  }

  :-ms-input-placeholder {
    color: rgba(40, 40, 40, 0.7);
    opacity: 1;
    font-weight: 400;
  }

  &:focus {
    border: 2px solid ${props => props.inputerror ? 'rgba(221, 19, 19, 0.5)' : 'rgba(40, 40, 40, 0.5)'};
    background-color: #fff;
  }
`

export const Input = ({
    inputType,
    placeholder,
    value,
    onChange,
    name,
    isMask,
    width,
    inputerror
  }) => {
    const setTypeForInput = () => {
      if (inputType === 'name') {
          return 'text'
      }
      if(inputType === 'email') {
          return 'email'
      }
    }

    return (
        <>
            {isMask
                ? <MaskInputElement
                    width={width}
                    value={value}
                    onChange={onChange}
                    name={name}
                    type={setTypeForInput() || 'text'}
                    placeholder={'Телефон'}
                    inputMode={'numeric'}
                    mask="+7 (999) 999-99-99"
                    inputerror={inputerror}
                />
                : <InputElement
                    as='input'
                    value={value}
                    width={width}
                    onChange={onChange}
                    name={name}
                    type={setTypeForInput() || 'number'}
                    placeholder={placeholder}
                    inputerror={inputerror}
                />}
        </>
    )
}
