import React, {useState} from 'react'
import styled from 'styled-components'
import {useFormik} from 'formik'
import {InputComponent} from "../../components/input";
import axios from "axios";
import {ProductsFormStatus} from "../../components/formStatus";
import ym from "react-yandex-metrika";
import {downloadFile} from "../../helpers/utils";
import { emailRegexp } from '../../helpers/regex';

const FormBlockWrap = styled.div`
  margin: 0;
  width: 100%;
  max-width: 930px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const FormBox = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const FormFields = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
const ButtonForSend = styled.button`
  outline: none;
  padding: 20px 50px;
  width: fit-content;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 676;
  font-size: 20px;
  line-height: 20px;
  color: #FFFFFF;
  font-stretch: 132%;
  font-variation-settings: 'GRAD' -188, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 98, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738, 'opsz' 42;

  &[disabled] {
    cursor: initial;
    pointer-events: none;
    color: #aaa;
    border-color: #aaa;
  }
`
const MainWrapper = styled.div`
  display: block;
`

export const Form = ({closeForm}) => {
  const [formStatus, setFormStatus] = useState({success: '', error: ''})

  const initialValues = {
    name: '',
    phone: '',
    email: '', // необязательное поле
  };

  const onSubmit = (values, { resetForm }) => {
    axios.post('/send', { ...values })
      .then(function () {
        ym('reachGoal', 'zayavka');
        setFormStatus({...formStatus, success: <>Ваша заявка<br /> принята</>});
        downloadFile('/download/7 правил и 10 выгодных активов.pdf', '7 правил и 10 выгодных активов.pdf');
        resetForm();
        formik.setSubmitting(false);
      })
      .catch(function (error) {
        console.error(error);
        setFormStatus({...formStatus, error: <>Произошла техническая ошибка!<br/> Попробуйте снова.</>});
        formik.setSubmitting(false);
      });
  }

  const validate = (values) => {
    let errors = {};

    // name
    if (!values.name) {
      errors.name = 'Обязательное поле';
    }

    // phone
    if (!values.phone) {
      errors.phone = 'Обязательное поле';
    }
    if (values.phone && values.phone.replace(/\D/g, '').length < 11) {
      errors.phone = 'Некорректный номер'
    }

    // email
    if (values.email && !emailRegexp.test(values.email)) {
      errors.email = 'Неверный формат почты'
    }

    return errors;
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  })

  const closeFormHandle = () => {
    closeForm()
    setFormStatus({})
  }

  const disabled = formik.isSubmitting || 
    !formik.isValid || 
    (!formik.values.name && !formik.values.phone);

  return (
    <MainWrapper>
      <FormBlockWrap>
        {formStatus.success || formStatus.error
          ? <ProductsFormStatus closeForm={closeFormHandle} status={formStatus} getBackToForm={() => setFormStatus({})} autoWidth/>
          : null
        }
        <FormBox onSubmit={formik.handleSubmit} noValidate>
          <FormFields>
            <InputComponent
              inputMargin='0 0 20px'
              inputType='name'
              placeholder='Имя'
              inputWidth='100%'
              notFullWidth
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              inputerror={formik.errors.name}
            />
            <InputComponent
              inputMargin='0 0 20px'
              name='phone'
              inputType='phone'
              placeholder='Телефон'
              notFullWidth
              inputWidth='100%'
              isMask={true}
              value={formik.values.phone}
              onChange={formik.handleChange}
              inputerror={formik.errors.phone}
            />
            <InputComponent
              inputMargin="0 0 20px"
              inputType="email"
              inputWidth='100%'
              notFullWidth
              placeholder="Email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              inputerror={formik.errors.email}
            />
            <ButtonForSend type="submit" disabled={disabled}>Получить</ButtonForSend>
          </FormFields>
        </FormBox>
      </FormBlockWrap>
    </MainWrapper>
  )
}