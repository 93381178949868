import React from 'react'
import styled from 'styled-components'

import successImage from '../../assets/images/form/Frame 188.png'
import errorImage from '../../assets/images/form/errorProductsStatusForm.svg'
import closeSbg from '../../assets/images/close.svg'
import cf from '../../assets/images/c1.svg'
import CustomMedia from "../../helpers/media";

const BGWrap = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.61);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  cursor: pointer;
`

const MainFormStatusBlockWrap = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #c8c8c8;
  padding: 30px 105px 55px;
  background: #fff;
  width: 560px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 20px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 100;
  ${CustomMedia.lessThan('mobile')`
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    padding: 30px 40px 55px;
  `}
`
const StatusImageWrapper = styled.div`
  width: 75px;
  height: 75px;
  margin: 0 0 10px;
`

const StatusImage = styled.img`
  width: 100%;
  height: 100%
`

const CloseIcon = styled.img`
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  z-index: 105;
`

const P = styled.p`
  font-weight: 834;
  font-size: 35px;
  line-height: 100%;
  text-align: center;
  color: #000;
  ${CustomMedia.lessThan('mobile')`
    font-size: 20px;
  `}
`

const Cf = styled.img`
  width: 329px;
  height: 275px;
  position: absolute;
  z-index: 101;
  top: 0;
  right: 0;
`

const PP = styled.p`
  font-weight: 300;
  margin-top: 22px;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #000000;
  ${CustomMedia.lessThan('mobile')`
    font-size: 14px;
  `}
`

export const ProductsFormStatus = ({status, withBorder, autoWidth, closeForm}) => {
  return (
    <BGWrap onClick={closeForm}>
      <MainFormStatusBlockWrap
        withBorder={withBorder}
        autoWidth={autoWidth}
      >
        <Cf src={cf}/>
        <CloseIcon src={closeSbg} onClick={closeForm}/>
        <StatusImageWrapper>
          <StatusImage src={status.success ? successImage : errorImage}/>
        </StatusImageWrapper>
        <P>{status.success || status.error}</P>
        <PP>{status.success && <>Файл загружен на ваше устройство в папку &quot;Загрузки&quot;.<br /> Специалист свяжется с вами позже <br /> и ответит на возникшие вопросы</>}</PP>
      </MainFormStatusBlockWrap>
    </BGWrap>
  )
}
