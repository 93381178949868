import React, {useState, useEffect} from "react";
import styled from "styled-components";
import FirstScreen from "../blocks/FirstScreen";
import FormWrap from "../blocks/FormWrap";
import Footer from "../blocks/Footer";
import SubFooter from "../blocks/SubFooter";
import Policy from "../blocks/policy";

import ym, {YMInitializer} from "react-yandex-metrika";

const Wrapper = styled.div`
  width: 100%;
  min-width: 100%;
  min-height: 100vh;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #2D3237;
`

function Main() {
  const [show, setShow] = useState(false)
  const [showForm, setShowForm] = useState(false)

  const METRIKA_YM_ID = 89341276;

  useEffect(() => {
    // YM send hit (page visited)
    ym('hit', '/');
  });

  const ymParams = {
    accounts: [METRIKA_YM_ID],
    options: {
        defer: true, // for use in SPA
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
    },
    version: '2', // for YM webvisor 2.0
};

  return (
    <Wrapper>
      <YMInitializer {...ymParams} />
      
      <FirstScreen setShowForm={setShowForm}/>
      <FormWrap show={showForm} setShow={setShowForm} />
      <SubFooter />
      <Footer callback={() => setShow(true)} />
      <Policy callback={() => setShow(false)} show={show} />
    </Wrapper>
  );
}

export default Main;
