import React from 'react'
import styled from 'styled-components'
import CustomMedia from "../../helpers/media";

const Wrapper = styled.div`
  padding: 53px 100px 17px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  ${CustomMedia.lessThan('mobile')`
    padding: 40px 20px 40px;
    max-width: 375px;
    margin: 0 auto;
  `}
`

const LargeText = styled.p`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 146.52%;
  color: ${props => props.color ? '#000' : '#fffbfb'};
  border-top: 1px solid #535353;
  padding-top: 40px;
  ${CustomMedia.lessThan('mobile')`
    width: 100%;
  `}
`

export default function SubFooter({cc}) {
  return (
    <Wrapper>
      <LargeText color={cc}>
        • указанная доходность не является гарантией результата и основана исключительно на исторических данных.
        Обращаем ваше внимание на то, что данный интернет-сайт, а также вся информация о товарах и ценах,
        предоставленная на нём, носит исключительно информационный характер и ни при каких условиях не является
        публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса Российской Федерации.<br /><br/> Деятельность
        организации строго ограничивается обучением, консультациями и помощью в заключении партнёрства с различными
        компаниями, а также оказанием информационной и аналитической поддержки. 
        ИП Кржижевская Лариса Александровна ОГРНИП 310774625701119 ИННИП 773134076804 собирает, анализирует и систематизирует информацию о поведении цен на различные финансовые активы и нематериальные активы, 
        по поручению своих клиентов.
      </LargeText>
    </Wrapper>
  )
}
