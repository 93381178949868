import React from 'react'
import styled from 'styled-components'
import CustomMedia from "../../helpers/media";
import scrollToElement from 'scroll-to-element';
import bg from '../../assets/images/bg.png'
import img1 from '../../assets/images/firstblock/1.svg'
import img2 from '../../assets/images/firstblock/2.svg'
import img3 from '../../assets/images/firstblock/3.svg'
import img4 from '../../assets/images/firstblock/4.svg'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: url(${bg}) no-repeat;
  background-size: 100% 100%;
  padding: 73px 100px;
  ${CustomMedia.lessThan('mobile')`
    height: auto;
    padding: 20px 20px;
  `}
`

const Container = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  ${CustomMedia.lessThan('mobile')`
    max-width: 375px;
  `}
`

const Title = styled.h1`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 115%;
  text-align: right;
  color: #FFFFFF;
  display: block;
  margin-bottom: 10px;
  ${CustomMedia.lessThan('mobile')`
  display: none;
    font-size: 50px;
    text-align: left;
  `}
`
const MobTitle = styled.h1`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  line-height: 115%;
  text-align: left;
  color: #FFFFFF;
  display: none;
  margin-bottom: 15px;
  ${CustomMedia.lessThan('mobile')`
  display: block;
  `}
`

const YellowTitle = styled.h2`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 115%;
  text-align: right;
  color: #FFC907;
  ${CustomMedia.lessThan('mobile')`
    font-size: 26px;
    text-align: left;
  `}
`

const ContentBlock = styled.div`
  display: flex;
  align-items: end;
  ${CustomMedia.lessThan('mobile')`
    align-items: start;
    flex-direction: column-reverse;
  `}
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-top: 28px;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  ${CustomMedia.lessThan('mobile')`
    margin-bottom: 27px;
  `}
  &:last-child {
    margin: 0;
  }
`

const ImageItem = styled.img`
  height: 70px;
  margin-right: 25px;
  ${CustomMedia.lessThan('mobile')`
    margin-right: 15px;
    height: 56px;
  `}
`

const ItemDesc = styled.p`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 125%;
  color: #FFFFFF;
  width: ${props => props.width || 'auto'};
  ${CustomMedia.lessThan('mobile')`
      width: ${props => props.mobWidth || 'auto'};
      font-size: 18px;
  `}
`

const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${CustomMedia.lessThan('mobile')`
    align-items: start;
    margin-top: 20px
  `}
`

const Description = styled.p`
  display: flex;
  flex-wrap: wrap;
  color: ${props => props.color || '#fff'};
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  justify-content: center;
  ${CustomMedia.lessThan('mobile')`
    display: none;
  `}
`

const MobDescription = styled.p`
  display: none;
  flex-wrap: wrap;
  color: ${props => props.color || '#fff'};
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  ${CustomMedia.lessThan('mobile')`
    display: flex;
  `}
`

const Button = styled.div`
  cursor: pointer;
  width: 303px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFC907;
  border-radius: 8px;
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 146.52%;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 30px;
  ${CustomMedia.lessThan('mobile')`
    width: 335px;
    margin-bottom: 40px;
  `}
`

/* eslint-disable */
export default function ({setShowForm}) {

  const onclick = () => {
    scrollToElement('#form', {
      offset: 0,
      ease: 'linear',
      duration: 500,
    })
  }

  return (
    <Wrapper>
      <Container>
        <Title>Что делать с рублями в 2024 году?</Title>
        <MobTitle>Что делать<br /> с рублями<br/> в 2024 году?</MobTitle>
        <YellowTitle>преврати их в активы<br />
          с доходностью до 19.3%*</YellowTitle>
        <ContentBlock>
          <ItemWrapper>
            <Item>
              <ImageItem src={img1} alt="" />
              <ItemDesc width='311px' mobWidth='281px'>Узнай, как действовать при дальнейшем понижении % ставок по депозитам</ItemDesc>
            </Item>
            <Item>
              <ImageItem src={img2} alt="" />
              <ItemDesc width='262px' mobWidth='262px'>Узнай какие действия можно предпринять при рекордной инфляции</ItemDesc>
            </Item>
            <Item>
              <ImageItem src={img3} alt="" />
              <ItemDesc width='309px' mobWidth='276px'>Будь в курсе, какие сырьевые активы будут расти в ближайшие полгода</ItemDesc>
            </Item>
            <Item>
              <ImageItem src={img4} alt="" />
              <ItemDesc width='312px' mobWidth='275px'>Посмотри какие компании в РФ обладают самыми высоким потенциалом в +30% и позволят получать прибыль </ItemDesc>
            </Item>
          </ItemWrapper>
          <DescriptionBlock>
            <Description>
              <Description color='#FFC907'>Получи список 10 самых выгодных активов для успешных</Description>
              <br />
              <Description color='#FFC907'>инвестиций и подробное описание торговой стратегии на</Description>
              <br />
              <Description color='#FFC907'>2024 год</Description>
              <Description>, в которой комплексно рассмотрены акции,</Description>
              <br />
              <Description>облигации, металлы, сырьевые активы, обладающие</Description>
              <br />
              <Description>потенциалом роста до конца текущего года.</Description>
            </Description>
            <MobDescription>
              <MobDescription color='#FFC907'>Получи список 10 самых выгодных </MobDescription>
              <br />
              <MobDescription color='#FFC907'>активов для успешных инвестиций и </MobDescription>
              <br />
              <MobDescription color='#FFC907'>подробное описание торговой</MobDescription>
              <br />
              <MobDescription color='#ffc907'>стратегии на 2024 год</MobDescription>&nbsp;
              <MobDescription> , в которой</MobDescription>
              <br />
              <MobDescription>комплексно рассмотрены акции,</MobDescription>
              <br />
              <MobDescription>облигации, металлы, сырьевые</MobDescription>
              <br />
              <MobDescription>активы, обладающие потенциалом</MobDescription>
              <br />
              <MobDescription>роста до конца текущего года.</MobDescription>
            </MobDescription>
            <Button onClick={() => setShowForm(true)}>Получить</Button>
          </DescriptionBlock>
        </ContentBlock>
      </Container>
    </Wrapper>
  )
}
