import { saveAs } from 'file-saver';

export const downloadFile = (url, fileName) => {
    const options = {
        method: 'GET',
    };

    fetch(url, options)
        .then((fileResponse) => fileResponse.blob())
        .then((file) => saveAs(file, fileName))
        .catch((e) => console.error(e));
};

export const getExpirationDate = () => {
    const deadline = 25;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    const expirationDate = currentDay >= deadline 
        ? new Date(currentYear, currentMonth + 1, 0) 
        : new Date(currentYear, currentMonth, 0);
    
    return expirationDate.toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
    });
}