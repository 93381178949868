import React from 'react'
import styled from 'styled-components'
import CustomMedia from "../helpers/media";
import rubble from '../assets/images/firstblock/R.svg'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: #fff;
  padding: 102px 100px 70px;
  ${CustomMedia.lessThan('mobile')`
    height: auto;
    padding: 20px 20px;
  `}
`
const Container = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  ${CustomMedia.lessThan('mobile')`
    max-width: 375px;
  `}
`

const MainInfo = styled.div`
  display: flex;
  width: 100%;
  ${CustomMedia.lessThan('mobile')`
    flex-direction: column-reverse;
  `}
`

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 55px;
  line-height: 130%;
  text-transform: uppercase;
  color: #000000;
  ${CustomMedia.lessThan('mobile')`
    display: none;
  `}
`

const MobTitle = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 130%;
  text-transform: uppercase;
  color: #000000;
  display: none;
  ${CustomMedia.lessThan('mobile')`
    display: block
  `}
`

const SubTitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 126.02%;
  color: #000000;
  margin: 20px 0 38px;
  ${CustomMedia.lessThan('mobile')`
    margin: 10px 0 10px;
    font-size: 18px;
  `}
`

const SmallTitle = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #000000;
  ${CustomMedia.lessThan('mobile')`
    display: none;
  `}
`

const MobSmallTitle = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  display: none;
  line-height: 140%;
  color: #000000;
  ${CustomMedia.lessThan('mobile')`
    display: block;
  `}
`

const Button = styled.div`
  cursor: pointer;
  width: 303px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFC907;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 146.52%;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 30px;
  ${CustomMedia.lessThan('mobile')`
    width: 335px;
    margin-bottom: 40px;
  `}
`

const Img = styled.img`
  margin-left: 157px;
  ${CustomMedia.lessThan('mobile')`
    margin: 0 0 5px 0;
    width: 100px;
  `}
`

const ItemWrap = styled.div`
  margin: 170px 0 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${CustomMedia.lessThan('mobile')`
    margin: 100px 0 0;
  `}
`

const Item = styled.div`
  padding: 30px 40px;
  display: flex;
  align-items: center;
  background: #F1F1F1;
  border-radius: 14px;
  width: 607px;
  margin-bottom: 20px;
  ${CustomMedia.lessThan('mobile')`
    padding: 20px;
    width: 335px;
  `}
`

const Number = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 96px;
  line-height: 115%;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #000000;
  margin-right: 15px;
`

const Desc = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;
  color: #000000;
  width: ${props => props.width || 'auto'};
  ${CustomMedia.lessThan('mobile')`
      width: ${props => props.mobWidth || 'auto'};
      font-size: 16px;
  `}
`

export default function FirstScreenFive({setShowForm}) {
  return (
    <Wrapper>
      <Container>
        <MainInfo>
          <TextBlock>
            <Title>Что делать с рублями<br/> в 2024 году?</Title>
            <MobTitle>Что делать <br />с рублями <br/>в 2024 году?</MobTitle>
            <SubTitle>преврати их в активы<br/>
              с доходностью до 19.3%*</SubTitle>
            <SmallTitle>
              Получи список 10 самых выгодных активов для успешных<br/>
              инвестиций и подробное описание торговой стратегии на 2024<br/>
              год , в которой комплексно рассмотрены акции, облигации,<br/>
              металлы, сырьевые активы, обладающие потенциалом роста до<br/>
              конца текущего года.
            </SmallTitle>
            <MobSmallTitle>
              Получи список 10 самых выгодных активов<br />
              для успешных инвестиций и подробное<br />
              описание торговой стратегии на 2024 год , в<br />
              которой комплексно рассмотрены акции,<br />
              облигации, металлы, сырьевые активы,<br />
              обладающие  потенциалом роста до конца<br />
              текущего года.
            </MobSmallTitle>
            <Button onClick={() => setShowForm(true)}>Получить</Button>
          </TextBlock>
          <Img src={rubble} alt=''/>
        </MainInfo>
        <ItemWrap>
          <Item>
            <Number>1</Number>
            <Desc width='445px' mobWidth='227px'>Узнай, как действовать при дальнейшем понижении % ставок по депозитам</Desc>
          </Item>
          <Item>
            <Number>2</Number>
            <Desc width='445px' mobWidth='227px'>Узнай какие действия можно предпринять при рекордной инфляции</Desc>
          </Item>
          <Item>
            <Number>3</Number>
            <Desc width='445px' mobWidth='227px'>Будь в курсе, какие сырьевые активы будут расти в ближайшие полгода</Desc>
          </Item>
          <Item>
            <Number>4</Number>
            <Desc width='445px' mobWidth='230px'>Узнай, как действовать при дальнейшем понижении % ставок по депозитам</Desc>
          </Item>
        </ItemWrap>
      </Container>
    </Wrapper>
  )
}
