import React from 'react'
import styled from 'styled-components'
import CustomMedia from "../../helpers/media";

const Wrapper = styled.div`
  padding: 0 100px 40px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 0;
  width: 100%;
  max-width: 1440px;
  ${CustomMedia.lessThan('mobile')`
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 20px 40px;
    max-width: 375px;
    margin: 0 auto 0;
  `}
`

const LargeText = styled.p`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 146.52%;
  color: ${props => props.color ? '#000' : '#fffbfb'};
  ${CustomMedia.lessThan('mobile')`
    width: 100%;
  `}
`

const Policy = styled.p`
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-decoration-line: underline;
  color: ${props => props.color ? '#000' : 'rgba(255, 251, 251, 0.6)'};
  font-stretch: 125%;
  font-variation-settings: 'GRAD' -52, 'slnt' 0, 'XTRA' 468, 'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203, 'YTFI' 738;
  opacity: 0.6;
  ${CustomMedia.lessThan('mobile')`
    margin-top: 21px;
  `}
`

export default function Footer({callback, cc}) {
  return (
    <Wrapper>
      <LargeText color={cc}>
        ИП Кржижевская Лариса Александровна<br />
        info@tochtoponadobitsa.ru
      </LargeText>
      <Policy color={cc} onClick={callback}>Политика конфиденциальности:</Policy>
    </Wrapper>
  )
}
