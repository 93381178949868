import React from 'react'
import styled from 'styled-components'
import {Input} from "./input";

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.notFullWidth ? props.inputWidth : '100%'};
  margin: ${props => props.inputMargin};
`
const InputWrap = styled.div`
  position: relative;
`
const ValidStatusInfo = styled.span`
  display: ${props => props.inputerror ? 'block' : 'none'};
  color: rgba(221, 19, 19, 0.5);
  width: 100%;
  position: absolute;
  left: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
`

export const InputComponent = ({
  inputType,
  placeholder,
  value,
  onChange,
  name,
  inputTextMargin,
  inputWidth,
  inputMargin,
  withoutIcon,
  isMask,
  notFullWidth,
  inputWrapWidth,
  inputerror
  }) => {
    return (
      <MainWrapper inputMargin={inputMargin} notFullWidth={notFullWidth} inputWidth={inputWrapWidth}>
        <InputWrap>
          <Input
            value={value}
            onChange={onChange}
            name={name}
            inputType={inputType}
            placeholder={placeholder}
            inputTextMargin={inputTextMargin}
            width={inputWidth}
            withoutIcon={withoutIcon}
            isMask={isMask}
            inputerror={inputerror}
          />

          <ValidStatusInfo inputerror={inputerror}>
              {inputerror}
          </ValidStatusInfo>
        </InputWrap>
      </MainWrapper>
    )
  }