import React from 'react'
import styled from 'styled-components'
import {Form} from "../form";
import image from '../../assets/images/form/Illustration (1).svg'
import CustomMedia from "../../helpers/media";
import { getExpirationDate } from '../../helpers/utils';
import close from '../../assets/images/whiteClose.svg'

const Inner = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 88px 100px 0;
  ${CustomMedia.lessThan('mobile')`
    padding: 60px 20px 0;
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
  `}
`

const MainWrap = styled.div`
  padding: 40px 30px 89px;
  border-radius: 16px;
  background-color: #2D3237;
  display: flex;
  flex-direction: column;
  ${CustomMedia.lessThan('mobile')`
    padding: 40px 20px;
    height: 780px;
    max-width: 375px;
    margin: 0 auto;
  `};
  position: relative;
`

const MainTitle = styled.p`
  font-weight: 800;
  font-size: 32px;
  line-height: 135%;
  color: #FFFFFF;
  ${CustomMedia.lessThan('mobile')`
    display: none;
  `}
`

const MainTitleMob = styled.p`
  font-size: 23px;
  line-height: 150%;
  font-weight: 600;
  color: #fff;
  display: none;
  ${CustomMedia.lessThan('mobile')`
    display: block;
  `}
`

const SubMainTitle = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #FFFFFF;
  opacity: 0.7;
  margin: 11px 0 0;
`

const MainButton = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 77px;
  width: fit-content;
  margin-top: 55px;
  font-style: normal;
  position: relative;
  cursor: pointer;
  z-index: 200;
  font-weight: 700;
  font-size: 20px;
  line-height: 146.52%;
  letter-spacing: 0.02em;
  color: #000000;
  ${CustomMedia.lessThan('mobile')`
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const BookImg = styled.img`
  position: absolute;
  width: 680px;
  bottom: 0;
  right: 0;
  z-index: 100;
  ${CustomMedia.lessThan('mobile')`
    width: 460px;
    bottom: 0;
    right: -25px;
  `}
`

const FormPopUp = styled.div`
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  padding: 0 100px;
  left: 0;
  display: ${props => props.show ? 'flex' : 'none'};
  z-index: 2000;
  ${CustomMedia.lessThan('mobile')`
    padding: 40px 10px;
  `}
`

const Bg = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
`

const Wrapper = styled.div`
  background-color: #2D3237;
  padding: 40px 60px;
  border-radius: 20px;
  width: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3000;
  ${CustomMedia.lessThan('mobile')`
    padding: 20px 20px 80px;
    height: 90vh;
    overflow: scroll;
  `}
`

const TopInner = styled.div`
  display: flex;
  ${CustomMedia.lessThan('mobile')`
    flex-direction: column;
  `}
`

const TopInnerItem = styled.div`
  width: 50%;
  padding: ${props => props.padding ? '0 50px 0 10px' : '0'};
  ${CustomMedia.lessThan('mobile')`
    width: 100%;
    padding: 0;
  `}
`

const TopTitle = styled.p`
  font-weight: 834;
  font-size: 46.1959px;
  line-height: ${props => props.opacity ? '46px' : '53px'};
  color: #fff;
  opacity: ${props => props.opacity ? '1' : '0.6'};
  ${CustomMedia.lessThan('mobile')`
    font-size: 30px;
    line-height: ${props => props.opacity ? '30px' : '36px'};
  `}
`

const TopDesc = styled.p`
  margin-top: 22px;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #FFFFFF;
  opacity: 0.6;
  ${CustomMedia.lessThan('mobile')`
    margin-top: 10px;
    margin-bottom: 20px;
  `}
`

const CustomHref = styled.div`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin: 55px 0 30px;
  ${CustomMedia.lessThan('mobile')`
    margin: 40px 0;
  `}
`

const Footer = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  opacity: 0.6;
`

const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
  cursor: pointer;
`



export default function FormWrap({setShow, show}) {

  return (
    <>
      <FormPopUp show={show}>
        <Bg onClick={() => setShow(false)} />
        <Wrapper>
          <CloseIcon onClick={() => setShow(false)} src={close} />
          <TopInner>
            <TopInnerItem>
              <TopTitle opacity>Оставьте заявку,</TopTitle>
              <TopTitle>чтобы получить</TopTitle>
              <TopTitle>материалы на</TopTitle>
              <TopTitle>почту бесплатно.</TopTitle>
              <TopDesc>Действует до {getExpirationDate()}!</TopDesc>
            </TopInnerItem>
            <TopInnerItem padding>
              <Form closeForm={() => setShow(false)} />
            </TopInnerItem>
          </TopInner>
          <CustomHref />
          <Footer>
            Информация, представленная на сайте, не является основанием для принятия инвестиционных решений и дана исключительно в ознакомительных целях. 
            Деятельность организации строго ограничивается обучением, консультациями и помощью в заключении партнёрства с различными компаниями, а также оказанием информационной и аналитической поддержки. 
            ИП Кржижевская Лариса Александровна ОГРНИП 310774625701119 ИННИП 773134076804 собирает, анализирует и систематизирует информацию о поведении цен на различные финансовые активы и нематериальные активы, по поручению своих клиентов.
          </Footer>
        </Wrapper>
      </FormPopUp>
      <Inner>
        <MainWrap id='form'>
          <BookImg src={image}/>
          <MainTitle>+ Получите руководство по <br/> выбору активов на финансовых<br/> рынках в подарок</MainTitle>
          <MainTitleMob>+ Получите<br/> руководство по выбору<br/> активов на финансовых<br/> рынках в
            подарок</MainTitleMob>
          <SubMainTitle>Руководство содержит в себе 7 основных правил и методов<br /> определения наиболее потенциальных активов на<br /> финансовых рынках</SubMainTitle>
          <MainButton onClick={() => setShow(true)}>Скачать</MainButton>
        </MainWrap>
      </Inner>
    </>
  )
}
